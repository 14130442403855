/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: 'Poppins', sans-serif; }

.container {
  max-width: 900px;
  margin: 0px auto; }

h1 {
  font-size: 64px;
  line-height: 78.8px;
  font-weight: 500;
  margin-top: 32px;
  overflow-wrap: break-word;
  text-align: center;
  display: block; }

h3 {
  font-size: 35.2px;
  font-weight: 500;
  line-height: 46.9px;
  overflow-wrap: break-wrap;
  text-align: center; }
  @media screen and (max-width: 600px) {
    h3 {
      font-size: 29px;
      line-height: 39px; } }

h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  display: block; }
  @media screen and (max-width: 600px) {
    h4 {
      font-size: 20px;
      line-height: 20px; } }

p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28.8px;
  margin-top: 16px;
  overflow-wrap: break-word;
  text-align: center; }
  @media screen and (max-width: 600px) {
    p {
      line-height: 26px; } }

a {
  background: #a8a6a1;
  color: #fff;
  padding: 19px 32px;
  border-radius: 6.4px;
  border-color: #a8a6a1;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  opacity: 1;
  transition: .1s opacity linear;
  width: fit-content;
  margin: auto; }
  a:hover {
    text-decoration: underline;
    opacity: .8; }

.hero {
  background: url("../media/A5.jpg") no-repeat center;
  background-size: cover;
  overflow: hidden; }
  .hero .logo-container {
    max-width: 900px;
    margin: 0px auto; }
    .hero .logo-container .logo {
      background: url("../media/ogt_logo.png") no-repeat center;
      background-size: contain;
      height: 55px;
      width: 165px;
      margin: 10px 20px; }
      @media screen and (max-width: 768px) and (min-width: 450px) {
        .hero .logo-container .logo {
          width: 30%; } }
      @media screen and (max-width: 449px) {
        .hero .logo-container .logo {
          width: 50%; } }
  .hero .container {
    color: #fff;
    text-align: center;
    line-height: 28.8px;
    overflow-wrap: break-word;
    padding: 55px 25px; }
    @media screen and (max-width: 600px) {
      .hero .container {
        padding: 15px 20px; } }
    @media screen and (max-width: 600px) {
      .hero .container h1 {
        font-size: 50px;
        line-height: 60px; } }
    .hero .container a {
      margin: 17px; }

section.message {
  padding: 38.5px 25.7px; }
  @media screen and (max-width: 600px) {
    section.message {
      padding: 30px 20px; } }

section.org {
  display: flex;
  flex-direction: column;
  padding: 17px;
  margin: 0px 32px 44px; }
  @media screen and (max-width: 800px) {
    section.org {
      margin: 0px; } }
  @media screen and (max-width: 600px) {
    section.org {
      padding: 0px;
      margin: 0px 15px; } }
  section.org .left, section.org .right {
    padding-bottom: 42px;
    display: flex;
    flex-direction: row; }
    @media screen and (max-width: 600px) {
      section.org .left, section.org .right {
        flex-direction: column; } }
    section.org .left .givingimg, section.org .right .givingimg {
      width: 50%;
      background: url("../media/A8.jpg") no-repeat top center;
      background-size: cover;
      height: 430px;
      float: left; }
      @media screen and (max-width: 600px) {
        section.org .left .givingimg, section.org .right .givingimg {
          width: 100%;
          float: left;
          height: 250px; } }
    section.org .left .content, section.org .right .content {
      width: 50%;
      margin: auto 30px;
      text-align: center;
      float: right; }
      @media screen and (max-width: 600px) {
        section.org .left .content, section.org .right .content {
          width: fit-content;
          float: left;
          padding: 30px 0px 15px;
          margin: auto; } }
      section.org .left .content span, section.org .right .content span {
        font-size: 20px;
        line-height: 22px; }
      section.org .left .content a, section.org .right .content a {
        margin-top: 13px; }
  section.org .right {
    display: flex; }
    @media screen and (max-width: 600px) {
      section.org .right {
        padding-bottom: 30px; } }
    section.org .right .content {
      order: 1; }
      @media screen and (max-width: 600px) {
        section.org .right .content {
          order: 2; } }
    section.org .right .givingimg {
      order: 2;
      width: 50%;
      background: url("../media/pm-giving2.jpg") no-repeat top center;
      background-size: cover;
      height: 430px;
      float: right; }
      @media screen and (max-width: 600px) {
        section.org .right .givingimg {
          order: 1;
          width: 100%;
          height: 250px;
          background: url("../media/pm-giving.jpg") no-repeat top center;
          background-size: cover; } }

section.partners {
  padding: 30px 40px;
  background: #f7f7f7; }
  @media screen and (max-width: 600px) {
    section.partners {
      padding: 30px 20px; } }
  section.partners .container div {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; }
    @media screen and (max-width: 600px) {
      section.partners .container div {
        flex-direction: unset;
        display: block;
        height: 150px;
        max-width: 400px;
        margin: auto; } }
    @media screen and (max-width: 425px) {
      section.partners .container div {
        flex-direction: unset;
        display: block;
        height: 150px; } }
    section.partners .container div .papaMurphys,
    section.partners .container div .theorem,
    section.partners .container div .kgwtv,
    section.partners .container div .iheartmedia {
      height: 75px;
      width: 150px;
      margin: 0px 7px;
      display: inline-block; }
      @media screen and (max-width: 600px) {
        section.partners .container div .papaMurphys,
        section.partners .container div .theorem,
        section.partners .container div .kgwtv,
        section.partners .container div .iheartmedia {
          float: left;
          display: block;
          width: 50%;
          margin: 0px auto;
          background-size: 155px !important; } }
      @media screen and (max-width: 425px) {
        section.partners .container div .papaMurphys,
        section.partners .container div .theorem,
        section.partners .container div .kgwtv,
        section.partners .container div .iheartmedia {
          float: left;
          display: block;
          width: 50%;
          margin: 0px auto;
          background-size: 155px !important; } }
      @media screen and (max-width: 374px) {
        section.partners .container div .papaMurphys,
        section.partners .container div .theorem,
        section.partners .container div .kgwtv,
        section.partners .container div .iheartmedia {
          background-size: 130px !important; } }
    section.partners .container div .papaMurphys {
      background: url("../media/papa-murphys-updated-logo.png") no-repeat center;
      background-size: contain; }
    section.partners .container div .theorem {
      background: url("../media/Theo-New-Logo-02.png") no-repeat center;
      background-size: contain; }
    section.partners .container div .kgwtv {
      background: url("../media/kgwtv.png") no-repeat center;
      background-size: contain;
      display: none; }
    section.partners .container div .iheartmedia {
      background: url("../media/iheartradio.png") no-repeat center;
      background-size: contain;
      display: none; }

footer {
  background: #38383b;
  color: #fff;
  padding: 22px 33px; }
  @media screen and (max-width: 699px) {
    footer {
      padding: 15px 20px; } }
  @media screen and (max-width: 699px) {
    footer .container .legal, footer .container .copywrite {
      font-size: 12px;
      line-height: 18px; } }
